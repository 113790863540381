import { FC, useContext, useEffect, useMemo, useRef, memo, useState } from "react";
import { ArtDomainViewStruct, ArtDomainProps } from './type';
import NameItem from "./NameItem";
import * as clipboard from "clipboard-polyfill";
import { message } from 'antd';
const domtoimage = require("dom-to-image");

const DomainNameView: FC<ArtDomainProps> = ({
    assetResult,
    imageWidth=300,
    imageHeight=300
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState<string>();
    const posterRef = useRef<HTMLInputElement>(null)
    const [messageApi, contextHolder] = message.useMessage();

    const onCickHandle = async (name?: string) => {     
      setIsModalOpen(!isModalOpen);
      if (name) {
        setName(name);
      }
    }

    const onDownloadHandle = () => {
        if (!posterRef.current) {
            return
        }
        
        let currentTop = posterRef.current.style.top;
        let currentLeft = posterRef.current.style.left;
        posterRef.current.style.top = "0";
        posterRef.current.style.left = "0";

        domtoimage
            .toPng(posterRef.current, { quality: 1 })
            .then(function (dataUrl: string) {
                posterRef!.current!.style.top = currentTop;
                posterRef!.current!.style.left = currentLeft;
                var link = document.createElement("a")
                link.download = `art_name_service.png`
                link.href = dataUrl
                link.click()
            })
            
    }

    const onCopyHandle = async () => {
        if (!posterRef.current) {
            return
        }

        let currentTop = posterRef.current.style.top;
        let currentLeft = posterRef.current.style.left;
        posterRef.current.style.top = "0";
        posterRef.current.style.left = "0";
        posterRef.current.style.zIndex = "-10";
        

        domtoimage
            .toPng(posterRef.current, { quality: 1 })
            .then(async (dataUrl: string) => {
                posterRef!.current!.style.top = currentTop;
                posterRef!.current!.style.left = currentLeft;
                
                const width = 900
                const height = 500
                const image = new Image()
                image.width = width
                image.height = height
                image.src = dataUrl
                image.onload = function () {
                    const canvas = document.createElement("canvas")
                    canvas.width = width
                    canvas.height = height
                    const ctx = canvas.getContext("2d")
                    ctx!.drawImage(image, 0, 0, width, height)
                    // document.getElementById("image-con").appendChild(image)
                    canvas.toBlob(async (blob) => {
                        await clipboard.write([
                            new clipboard.ClipboardItem({
                                [blob!.type]: blob!,
                            }),
                        ])
                        messageApi.info('Copied!');
                    })
                }
            })
    }

    return (
        <div className="domain-root">
            {contextHolder}
            <div className="domainHandleGrid-root">
            {
                assetResult?.assets.map((asset, i) => {
                    return <NameItem
                            key={`name:${i}`}
                            artDomain={asset as ArtDomainViewStruct}
                            width={imageWidth}
                            height={imageHeight}
                            shareHandle={onCickHandle}
                        />;
                })
            }
            </div>
            {   
                isModalOpen && (
                    <div className="domain-model" >
                        <div className='domain-background' onClick={()=>onCickHandle()}></div>
                        <div className="domain-model-content" ref={posterRef}>
                            <img className="domain-image" src={"/share/poster.png"}/>
                            <div className="domain-model-text">
                                <div className="domain-model-text-box">
                                    <span className="domain-model-name">{name!.length > 20 ? name?.slice(0, 20) + "..." : name}</span>
                                    <span className="domain-model-tld">{".art"}</span>
                                </div>
                            </div>
                        </div>
                        <div className="domain-model-button">
                            <img className="domain-model-button-image" src={"/share/copy.png"} onClick={onCopyHandle}/>
                            <img className="domain-model-button-image" src={"/share/download.png"} onClick={onDownloadHandle}/>
                        </div>
                    </div>
                )
            }
        </div>
    )
    
};

export default memo(DomainNameView);
