import { Typography } from "antd";
import { FC } from "react";

interface DescriptionProps {
  title: string;
  paragraph: string;
  list?: string[];
}

const Description: FC<DescriptionProps> = ({
  title,
  paragraph,
  list
}) => {
    return (
      <div className="description-root">
        <Typography.Title level={2}>{title}</Typography.Title>
        <Typography.Paragraph className="paragraphs"> {paragraph}</Typography.Paragraph>
        {
          list && 
          <ul>
            {
            list.map((v, i) => {
              return <li key={i}>
                      <Typography.Text className="list-item"> {v} </Typography.Text>
                    </li>;
            })
            }
          </ul>
        }
      </div>
    )
    
}

export default Description;