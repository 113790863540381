import { Layout } from "antd";
import { FC, ReactNode } from "react";
// import BackBtn from "../Buttons/BackBtn";
import DefaultNavbar from "../Navbar";
import ScrollProvider from "../ScrollbarContext";

interface DefaultLayoutProps {
    children: ReactNode,
    disabledBack?: boolean,
}

const DefaultLayout: FC<DefaultLayoutProps> = ({children, disabledBack}) => {
    return (
        <Layout>
            <Layout.Header>
                <DefaultNavbar />
            </Layout.Header>
            <Layout.Content>
                <ScrollProvider >
                    {/* { !disabledBack && <BackBtn handleClick={()=>{history.goBack()}} />} */}
                    {children}
                </ScrollProvider>
            </Layout.Content>
        </Layout>
    )
};

export default DefaultLayout;