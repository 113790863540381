import { Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

type Options = Record<string, {
  description: string;
  route: string;
}>

interface PropsType {
  defaultKey: string
  options: Options
}

const PageSelector: FC<PropsType> = ({
  defaultKey,
  options
}) => {
  const [selectedValue, setSelectedValue] = useState<string>("");
  const location = useLocation();

  useEffect(()=>{
    setSelectedValue(defaultKey);
  })

  let optionKeys = Object.keys(options);

  let values: Record<string, string> = {
    "copies": 'View Copies',
    "creations": "View Creations"
  }
  
  let history = useHistory();

  const onChangeHandle = (value: string) => {
    setSelectedValue(value);
    history.push(options[value].route);
  }

  return (
          <Select
          dropdownClassName='display-item'
          value={selectedValue}
          onChange={onChangeHandle}
      >
          {optionKeys.map((o) => 
            <Select.Option 
              key={o}
              className='display-list-item' 
              value={o} >
                {options[o].description}
            </Select.Option>)}
          </Select>
  );
};

export default PageSelector;
