import { FC, useContext, useEffect, useState } from "react";
import { MintableViewStructOutput } from "../../controllers/contracts/typechain-types/Helper";
import { Col, Row, Typography, Divider, Space, message } from 'antd';
import { IWeb3Context } from "../../controllers/web3/type";
import Web3Context from "../../controllers/web3/context";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import NormalButton from "../Buttons/NormalBtn";
import { resolveCopyright, resolveMintOpening, resolveMintFee, resolveDuration, resolveBlockExplorerUrl } from "../../utils";
import { ZERO_ADDRESS } from "../../common/constants";
import { getNFTBalance, getFTBalance, getFTAllowance, getERC20Contract } from "../../controllers/contracts/utils";
import { BigNumber } from "ethers";
import Contracts from "../../controllers/contracts";
import { getContracts } from "../../common/contracts";

interface MintableItemProps {
  mintable: MintableViewStructOutput
}

const MintableItem: FC<MintableItemProps> = ({
  mintable
}) => {

  const {targetNetwork, getSigner, isTargetNetwork } = useContext<IWeb3Context>(Web3Context);
  const [validNFT, setValidNFT] = useState<boolean>();
  const [validBalance, setValidBalance] = useState<boolean>(false);
  const [creatorExists, setCreatorExists] = useState<boolean>(false);

  const [isApproved, setIsApproved] = useState<boolean>(false);

  useEffect(()=>{
    validateOnChain();
    const intervalId = setInterval(validateOnChain, 5000);
    return () => clearInterval(intervalId);
  },[])

  let maxCell = 24;
  let {mintInfo, valInfo, count} = mintable;

  let mintOpeningStatus = resolveMintOpening(valInfo.start, valInfo.time);

  const getMintOpeningText = () => {
    return mintOpeningStatus.state == 0 ? mintOpeningStatus.description : <p color="red">{mintOpeningStatus.description}</p>
  }

  const isMintBalanceApproved = async (): Promise<boolean> => {
    let fee = mintable.valInfo.mintAmount;
    let feeToken = mintable.valInfo.feeToken;

    if (feeToken == ZERO_ADDRESS || fee.eq(0)) return true;

    let signer = await getSigner();
    let mintableAddress = getContracts(targetNetwork)?.mintable;
    let allowance = await getFTAllowance(signer, feeToken, await signer.getAddress(), mintableAddress!);

    return (allowance as BigNumber).gte(fee);
  }

  const validateOnChain = () => {
    hasValidNFT().then(
      (v) => setValidNFT(v)
    )
    hasValidMintFee().then(
      (v) => setValidBalance(v)
    )
    isMintBalanceApproved().then(
      (v) => setIsApproved(v)
    )
    isCreatorExists().then(
      (v) => setCreatorExists(v)
    )
  }

  const hasValidNFT = async () => {
    if (valInfo.requiredERC721Token == ZERO_ADDRESS) return true;
    let signer = await getSigner();
    let balance = await getNFTBalance(signer, valInfo.requiredERC721Token, await signer.getAddress());
    return (balance as BigNumber).gt(0);
  }

  const hasValidMintFee = async () => {
    let signer = await getSigner();
    let balance: BigNumber;
    if ( valInfo.feeToken == ZERO_ADDRESS ) {
      balance = await signer.getBalance();
    } else {
      balance = await getFTBalance(signer, valInfo.feeToken, await signer.getAddress()) as BigNumber;
    }
    return (balance).gte(valInfo.mintAmount);
  }

  const isCreatorExists = async () => {
    let signer = await getSigner();
    let contracts = await Contracts(signer, targetNetwork);
    return await contracts.creator.exists(mintable.mintInfo.creatorId);
     
  }

  const onClickHandle = async () => {
    
    if (!await isTargetNetwork()) return;

    let signer = await getSigner();
    let contracts = await Contracts(signer, targetNetwork);
    
    if (! isApproved) {
      const tx = await getERC20Contract(signer, valInfo.feeToken).approve(contracts.mintable.address, valInfo.mintAmount);
      const receipt = await tx.wait();
      if (receipt.status == 1) {
        setIsApproved(true);
      }
    } else {
      const tx = await contracts.copy.create(
        await signer.getAddress(),
        mintable.copyHash,
        mintable.valInfo.duration,
        {value: valInfo.feeToken == ZERO_ADDRESS ? valInfo.mintAmount : 0}
      );
      let receipt = await tx.wait();
      if (receipt.status == 1) {
        message.info("Mint Successful");
      }
    }
  }
  
  return <Col className="creation-mintable" xs={maxCell} sm={maxCell} lg={maxCell} xl={24}>
          <Row gutter={[16, 16]}>
            <Col xs={maxCell} sm={maxCell} lg={maxCell} xl={10}>
              <div className="creation-mintable-item">

                <div className="creation-mintable-item-header">
                  <Typography.Title level={4}>Copy Info</Typography.Title>
                </div>

                <div>
                  <Space align="start">
                    <Typography.Paragraph>Copyright: </Typography.Paragraph>
                    <Typography.Paragraph> {resolveCopyright(mintInfo.statement)} </Typography.Paragraph>
                  </Space>
                </div>

                <div>
                  <Space align="start">
                    {mintInfo.transferable ? <CheckOutlined/> : <CloseOutlined/>}
                    <Typography.Paragraph>Transferable</Typography.Paragraph>
                  </Space>
                </div>

                <div>
                  <Space align="start">
                    {mintInfo.extendable ? <CheckOutlined/> : <CloseOutlined/>}
                    <Typography.Paragraph>Extendable</Typography.Paragraph>
                  </Space>
                </div>
                
                <div>
                  <Space align="start">
                    {mintInfo.updatable ? <CheckOutlined/> : <CloseOutlined/>}
                    <Typography.Paragraph>Updatable</Typography.Paragraph>
                  </Space>
                </div>

                <div>
                  <Space align="start">
                    {mintInfo.revokable ? <CheckOutlined/> : <CloseOutlined/>}
                    <Typography.Paragraph>Revokable</Typography.Paragraph>
                  </Space>
                </div>

              </div>
            </Col>
            <Col xs={maxCell} sm={maxCell} lg={maxCell} xl={10}>
              <div className="creation-mintable-item-header">
                <Typography.Title level={4}>Mint Condition</Typography.Title>
              </div>

              <div>
                  <Space align="start">
                    {mintOpeningStatus.state == 0 ? <CheckOutlined/> : <CloseOutlined/>}
                    <Typography.Paragraph> 
                      {getMintOpeningText()}
                    </Typography.Paragraph>
                  </Space>
              </div>

              <div>
                <Space align="start">
                {validBalance ? <CheckOutlined/> : <CloseOutlined/>}
                  <Typography.Paragraph> {resolveMintFee(valInfo.mintAmount, valInfo.feeToken, targetNetwork)} </Typography.Paragraph>
                </Space>
              </div>

              <div>
                <Space align="start">
                  {validNFT ? <CheckOutlined/> : <CloseOutlined/>}
                    {
                      valInfo.requiredERC721Token == ZERO_ADDRESS ? 
                      <Typography.Paragraph>Requires No NFT Holding</Typography.Paragraph> :
                      <Typography.Paragraph>
                        {"Requires "}
                        <a href={resolveBlockExplorerUrl(valInfo.requiredERC721Token, targetNetwork)} target="_blank" rel="noreferrer">NFT</a>
                        {" Holder"}
                      </Typography.Paragraph>
                    }
                </Space>
              </div>
              
              <div>
                  <Space align="start">
                    {count.lt(valInfo.limit) ? <CheckOutlined/> : <CloseOutlined/>}
                    <Typography.Paragraph>{"Limit " + count.toString() + "/" + valInfo.limit.toString()}</Typography.Paragraph>
                  </Space>
              </div>

              {creatorExists === false &&
                <div>
                  <Space align="start">
                    <CloseOutlined/>
                    <Typography.Paragraph> {"Creator Has Burnt the Creator Token"} </Typography.Paragraph>
                  </Space>
                </div>
              } 

            </Col>
            <Col xs={maxCell} sm={maxCell} lg={maxCell} xl={4}>
              <div className="creation-mintable-item-header">
                <Typography.Title level={4}>Collect Now</Typography.Title>
              </div>

              { valInfo.fragmented ?
                <div>
                    <Typography.Paragraph>{"Token Expiry: "}</Typography.Paragraph>
                    <Typography.Paragraph>{resolveDuration(valInfo.duration)}</Typography.Paragraph>
                </div> :
                <div>
                    <Typography.Paragraph>{"Token Expiry: "}</Typography.Paragraph>
                    <Typography.Paragraph>{resolveDuration(valInfo.duration)}</Typography.Paragraph>
                </div>
              }
              <div className="creation-mintable-mintbtn">
                <NormalButton 
                placeholder={isApproved ? 'Mint': "Approve"} 
                disabled={!   (
                              validNFT && 
                              validBalance && 
                              mintOpeningStatus.state == 0 && 
                              count.lt(valInfo.limit) && 
                              creatorExists
                              )
                         }
                onClick={onClickHandle}/>
              </div>

            </Col>
          </Row>

          <Divider className="creation-div"/>
        </Col>
}

export default MintableItem;