import { Typography } from "antd";
import { FC } from "react";
import DefaultLayout from "../../components/Layout";
import Description from "../../components/Description";

import { FAQS } from "../../common/faqs";

const FaqsPage: FC = () => {

    // TODO contract displaying

    return (
        <DefaultLayout disabledBack>
            <div className="faq-root">
              <Typography.Title level={1}>Frequently Asked Questions</Typography.Title>
              {
                FAQS.map(
                  (v, i) => {
                    return <Description key={i} title={(i+1).toString() + ") " + v.title} paragraph={v.paragraph} list={v.list}/>
                  }
                )
              }
              
            </div>
        </DefaultLayout>
    )
};

export default FaqsPage;