import { FC , useState} from "react";
import { ArtDomainGridItemProps } from '../../NFTGrid/type';
import { Tooltip, Button, Modal, Space  } from 'antd';

const NameItem: FC<ArtDomainGridItemProps> = ({
    artDomain,
    width,
    height,
    shareHandle
}) => {


  const formatName = (fullName: string) => {
    // nextline (insert \n) if full name is over 42 characters
    let nameArray = [];
    while (fullName.length > 42) {
      nameArray.push(fullName.slice(0, 42));
      fullName = fullName.slice(42, fullName.length);
    }
    nameArray.push(fullName);
    return nameArray.join("\n");
  }

  let name = artDomain?.name.length > 13 ? artDomain.name.slice(0, 13) + "..." : artDomain?.name;
  let bottomName = artDomain?.name.length > 23 ? artDomain.name.slice(0, 23) + "..." : artDomain?.name;
  let toolTipName = formatName(artDomain?.name + "." + artDomain?.tld);

  let owner = artDomain?.owner.slice(0, 5) + "..." + artDomain?.owner.slice(artDomain?.owner.length - 5, artDomain?.owner.length);

  return (
        <div 
          className="domain-display-root"
          style={{width: width, height: height}}
        >

          <div className="domain-title">
            <Tooltip className="domain-bottom-hint" placement="topLeft" title={toolTipName} trigger="hover">
              <span className="domain-name">{name}</span>
              <span className="domain-tld">{"." + artDomain?.tld}</span>
            </Tooltip>
          </div>
          
          <div className="float-items">
            <img src={"/logo.jpeg"} width={25} height={25}/>
          </div>

          <div className="float-icon">
            <img src={"/share/export.svg"} width={21} height={21} onClick={()=>shareHandle(artDomain?.name)}/>
          </div>
          {/* <div className="domain-bottom">
            <Tooltip className="domain-bottom-hint" placement="topLeft" title={toolTipName} trigger="hover">
              <span className="domain-bottom-name">{bottomName}</span>
            </Tooltip>
          </div> */}

          <div className="owner-bottom">
                <span className="owner-bottom-title">Owner</span>
          </div>
          
          <div className="owner-bottom-text">
            <Tooltip className="domain-bottom-address-hint" placement="topLeft" title={artDomain?.owner} trigger="hover">
              <span className="owner-bottom-address">{owner}</span>
            </Tooltip>
          </div>
  
        <div className="domain-display">
          <img className="display-bg" src={"/artNSBG.png"}/>
        </div>
      </div>
    )
};

export default NameItem;