import { FC, useRef } from 'react';
import { SelectorData } from '../../controllers/web3/storage/type';

interface PropsType {
  className?: string
  data?: SelectorData,
  width: number | string,
  height?: number | string,
  controls?: boolean
  hoverEffects?: boolean
}

const AssetView: FC<PropsType> = ({ 
  className,
  data,
  width,
  height,
  controls = true,
  hoverEffects = false
}) => {

  let video = useRef<HTMLVideoElement>(null!);

  if (!data) return <div style={{width: width, height: height}}></div>

  const dataType = data.type.split("/")[0];

  const baseClass = className ? className : 'cropped';
  
  const mouseOverHandler: React.MouseEventHandler<HTMLVideoElement> = () => {
    if (hoverEffects) video.current.play();
  }

  const mouseOutHandler: React.MouseEventHandler<HTMLVideoElement> = () => {
    if (hoverEffects) video.current.pause();
  }

  if (dataType === "video" || dataType === "audio") {
    
    let poster = data.meta?.picture ? `data: image/png; base64, ${data.meta?.picture?.toString('base64')}` : "";

    return  (
      <div >
        <video ref={video} className={`${baseClass} `} poster={poster} width={width} height={height} controls={controls} autoPlay={false} onMouseOver={mouseOverHandler} onMouseOut={mouseOutHandler}>
          <source src={data.url} type={data.type}/>
        </video>
      </div>
    );
  }

  return  <img src={data.url!} width={width} height={height} className={className ? className : 'cropped'}></img>
};

export default AssetView;
