import { FC, useEffect, useState } from "react";
import { CopyGridItemProps } from '../type';

import { useHistory } from "react-router";
import { ROUTE_COPY_ROOT } from "../../../common/route";

import { resolveCopyright, resolveExpiry } from "../../../utils";
import { BigNumber } from "ethers";
import useAssetDisplay from "../../../hooks/useAssetDisplay";
import AssetView from "../../AssetView";
import { selectData } from "../../../controllers/web3/storage/assetFormater";

const CopyNFTGridItem: FC<CopyGridItemProps> = ({
    copy,
    width,
    height
}) => {

  let history = useHistory();

  const data = useAssetDisplay(copy.tokenURI);

    const onCickHandle = () => {     
      history.push(ROUTE_COPY_ROOT + copy.copyId.toString());
    }

    return (
      <div className="image-display-root">
            {copy && 
              <div className="image-display" onClick={onCickHandle}>

                <div className="creation-charater float-items">
                  {copy.mintable.mintInfo.extendable && <span className="creation-charater-item">E</span>}
                  {copy.mintable.mintInfo.transferable && <span className="creation-charater-item">T</span>}
                  {copy.mintable.mintInfo.updatable && <span className="creation-charater-item">U</span>}
                  {copy.mintable.mintInfo.revokable && <span className="creation-charater-item">R</span>}
                </div>

                <div className="creation-mintables float-items">
                  {"Copyright: "}
                  {resolveCopyright(copy.mintable.mintInfo.statement.valueOf() as number)}
                </div>
                
                <div className="creation-copies float-items">
                  {resolveExpiry(copy.expireAt.valueOf() as BigNumber)}
                </div>
                
                <AssetView data={data ? selectData(data!, 0) : undefined} width={width} height={height}/>
              </div>}
        </div>
    )
};

export default CopyNFTGridItem;