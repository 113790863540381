import { FC, useContext, useEffect, useState } from "react";
import { Input, message, Typography } from "antd";
import { IWeb3Context } from "../../controllers/web3/type";
import Web3Context from "../../controllers/web3/context";

import NormalButton from "../../components/Buttons/NormalBtn";
import DefaultLayout from "../../components/Layout";

import { getContracts } from "../../common/contracts";
import { getNFTBalance, getFTBalance } from "../../controllers/contracts/utils";

import { BigNumber } from "ethers";
import Contracts from "../../controllers/contracts";
import { divideAmountByDecimals } from "../../utils";


const FaucetPage: FC = () => {

  const {targetNetwork, getSigner, isTargetNetwork, connectedWallet, connect } = useContext<IWeb3Context>(Web3Context);
  const [mpassBalance, setMpassBalance] = useState<BigNumber>();
  const [musdBalance, setMusdBalance] = useState<BigNumber>();

  useEffect(()=>{
    if (!connectedWallet) connect();
    isTargetNetwork();
  }, [])

  useEffect(()=>{
    isTargetNetwork().then(
      ()=>fetchInfoOnChain()
    )
    const intervalId = setInterval(fetchInfoOnChain, 5000);
    return () => clearInterval(intervalId);
  }, [])

  const fetchInfoOnChain = ()=>{
    getMPassBalance().then(
      (v)=>{setMpassBalance(v)}
    ).catch((err)=>{console.log(err)});

    getMusdBalance().then(
      (v)=>{setMusdBalance(v)}
    ).catch((err)=>{console.log(err)});
  }

  const getMPassBalance = async () => {
    let signer = await getSigner();
    let mockNFTAddress = getContracts(targetNetwork)!.test.mockNFT;
    let balance = await getNFTBalance(signer, mockNFTAddress, await signer.getAddress());
    return balance as BigNumber;
  }

  const getMusdBalance = async () => {
    let signer = await getSigner();
    let mockFT = getContracts(targetNetwork)!.test.mockFT;
    let balance = await getFTBalance(signer, mockFT, await signer.getAddress());
    return balance as BigNumber;
  }

  const onClickMintNFTHandle = async () => {
    
    if (!await isTargetNetwork()) return;

    let signer = await getSigner();
    let contracts = await Contracts(signer, targetNetwork);

    const tx = await contracts.test.mockNFT.mintToken(await signer.getAddress());
    await tx.wait();
    message.info("Mint MPASS Successful");

  }

  const onClickMintFTHandle = async () => {
    if (!await isTargetNetwork()) return;

    let signer = await getSigner();
    let contracts = await Contracts(signer, targetNetwork);

    const tx = await contracts.test.mockFT.mint(await signer.getAddress(), '10000000000000000000');
    await tx.wait();
    message.info("Mint MUSD Successful");
  }

  return (
      <DefaultLayout disabledBack>
          <div className="faucet-root">
            <Typography.Title level={1}>Claim Test NFT and FT Tokens</Typography.Title>

            <div className="faucet-token">
              <Typography.Title className="faucet-token-title" level={2}>Mock NFT Tokens ( MPASS )</Typography.Title>
              <div className="faucet-panel-div">
                <Typography.Text className="faucet-text"> {"Your Balance: " + (mpassBalance ? mpassBalance?.toString() : "0") }</Typography.Text>
                <div className="faucet-panel-button">
                  <NormalButton
                    placeholder={"Claim 1 DPass"}
                    onClick={onClickMintNFTHandle}/>
                </div>

              </div>
            </div>

            <div className="faucet-token">
              <Typography.Title className="faucet-token-title" level={2}>Mock FT Tokens ( MUSD )</Typography.Title>
              <div className="faucet-panel-div">
                <Typography.Text className="faucet-text"> {"Your Balance: " + (musdBalance ? divideAmountByDecimals(musdBalance, 18) : "0") }</Typography.Text>
                <div className="faucet-panel-button">
                  <NormalButton
                    placeholder={"Claim 10 MUSD"}
                    onClick={onClickMintFTHandle}/>
                </div>

              </div>
            </div>
          </div>
      </DefaultLayout>
    )
};

export default FaucetPage;