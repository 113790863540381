import { url } from "inspector";
import { ArData, AssetData, SelectorData } from "./type";

export const formatAsset = (data: AssetData ): ArData => {
  return {
    title: "",
    description: "",
    url: "",
    urlCoverImage: "MIYCcb0nnwyt0kqV7LQKmO2XNL4fnzlqEqmKgeeXiaQ", // string
    urlPublic: "MIYCcb0nnwyt0kqV7LQKmO2XNL4fnzlqEqmKgeeXiaQ", // placement
    urlEncrypted: "", // placement
    dataEncryptionKey: data.dataEncryptionKey,
    accessControlConditions: data.accessControlConditions, // string
    typeCoverImage: data.typeCoverImage ? data.typeCoverImage : "image/jpeg",
    typePublic: data.typePublic ? data.typePublic : "image/jpeg",
    typeEncrypted: data.typeEncrypted,
    format: "distributor-v0.1"
  }

}

export const selectData = (arData: ArData, dataType: number): SelectorData | undefined=> {
  switch (dataType) {
    case 0:
      return (arData.urlCoverImage && arData.typeCoverImage) ?
        {
          url: arData.urlCoverImage!,
          type: arData.typeCoverImage!
        } : undefined;
    case 1:
      return (arData.urlPublic && arData.typePublic) ?
        {
          url: arData.urlPublic!,
          type: arData.typePublic!
        } : undefined;
    case 2:
      return (arData.urlEncrypted && arData.typeEncrypted) ?
        {
          url: arData.urlEncrypted!,
          type: arData.typeEncrypted!
        } : undefined;
  }
  return undefined;
}