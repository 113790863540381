import { createContext, FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { IScrollContext } from "./type";
import { Scrollbars } from 'react-custom-scrollbars';
import debounce from "lodash.debounce";

const DEFAULT_VALUE: IScrollContext = {
    offset: 0,
}

export const ScrollContext = createContext(DEFAULT_VALUE);

interface ScrollProviderProps {
    children: ReactNode
}

const ScrollProvider: FC<ScrollProviderProps> = (props) => {


    const [ height, setHeight ] = useState<number>(0);
    const [ offset, setOffset ] = useState<number>(-1);

    const getHeaderHeight = () => {
        const el = document.getElementsByClassName('ant-layout-header');
        if (!el || el.length == 0 ) return 0;

        let hh = el[0].clientHeight;
        return hh;
    }
    
    const handleResize = () => {
        const headerHeight = getHeaderHeight();
        const _height = window.innerHeight - headerHeight;
        setHeight(_height)
    }

    const handleScroll = (e: any) => {
        const scrollTop = e.target.scrollTop;
        const _offset = e.target.scrollHeight - height;
        setOffset(scrollTop - _offset)
    }

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return (
        <ScrollContext.Provider value={{
            offset: offset,
        }}>
            <Scrollbars
                id='scrollbar'
                onScroll={handleScroll}
                style={{ height: height }}
                autoHide={false}
                renderThumbVertical={({ style, ...props }) =>
                    <div {...props} style={{ ...style, width: '3px', borderRadius: '4px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.16)', backgroundColor: '#ffffff' }} />
                }>
                    {props.children}
            </Scrollbars>

        </ScrollContext.Provider>
    )
};

export default ScrollProvider;