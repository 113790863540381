import Router from './router';

import Web3Provider from './controllers/web3/provider';
import StorageProvider from './controllers/web3/storage/storageProvider';

function App() {
  return (
    <div className="App">
      <Web3Provider>
        <StorageProvider>
          <Router />
        </StorageProvider>
      </Web3Provider>
    </div>
  );
}

export default App;
