import { useEffect, useState , useContext, FC, ReactNode, useRef} from "react";
import StorageContext from "./storageContext";
import { ArData, AssetData } from "./type";
import { bundlerUrl } from '../../../common/config';
import Web3Context from "../../../controllers/web3/context";
import { IWeb3Context } from "../../../controllers/web3/type";
import { getProvider } from "../../../controllers/web3/erc/utils";

import { WebBundlr } from "@bundlr-network/client";
import { message } from "antd";
import BigNumber from "bignumber.js";
import { fetchHead, fetchContent, fetchDisplayContent } from "../../api";

import { formatAsset } from "./assetFormater";

interface StorageProviderProps {
  children: ReactNode
}

const StorageProvider : FC<StorageProviderProps> = ({ children }) => {

  const { connect, isPolygon } = useContext<IWeb3Context>(Web3Context);
  
  let currency = 'matic';

  const [assetData, setAssetData] = useState<AssetData>({dataDecrypted: Buffer.from("")});

  const [balance, setBalance] = useState<any>();
  const [price, setPrice] = useState<any>();
  const [bundlr, setBundlr] = useState<WebBundlr | undefined>();

  const [arid, setArid] = useState<string | undefined>();

  const [arData, setArData] = useState<ArData | undefined>();

  useEffect(()=>{
    if (assetData.dataEncrypted || assetData.dataPublic || assetData.coverImage) {
      console.log('checking price');
      checkPrice();
    }
  }, [assetData])

  useEffect(() => {
    const interval = setInterval(() => {
      checkBalance();
    }, 3000);
  
    return () => clearInterval(interval);
  }, [bundlr]);

  useEffect(()=> {
    // try recover arid
    let arid_ = localStorage.getItem("arid");
    if (arid_ && arid_ != "") {
      setArid(arid_);
    }
  })

  useEffect(()=>{
    if (arid) getRedirectedArUri();
  }, [arid])

  const initBundlr = async () => {
    
    await connect();
    await isPolygon();
    const wallet = await getProvider();
    const bundlr = new WebBundlr(bundlerUrl, currency, wallet)
    try {
      // Check for valid bundlr node
      const bundlrAddress = await bundlr.utils.getBundlerAddress(currency);
      console.log(bundlrAddress);
    } catch {
      message.error(`Failed to connect to bundlr ${bundlerUrl}`)
      return;
    }
    try {
      await bundlr.ready();
    } catch (err) {
      console.log(err);
    } //@ts-ignore
    if (!bundlr.address) {
      console.log("something went wrong");
      return;
    }
    message.success(`Connected to ${bundlerUrl}`)
    setBundlr(bundlr);
  }

  // top up fund to bundlr account
  const fund = async (value: BigNumber ) => {
    if (bundlr) {
      await isPolygon();
      message.info({content: "Funding...", key: "fundingMessage", duration: 100000});
      if (!value) return
      await bundlr.fund(value)
        .then(res => { 
          message.destroy("fundingMessage");
          message.success(`Funded ${res?.target}. tx ID : ${res?.id}`) }
          )
        .catch(e => {
          message.error(`Error: Failed to fund - ${e.data?.message || e.message}`)
        })
    }
  };

  const checkPrice = async (): Promise<void> => {
    // check the public and encrypted data first, then check the meta data price and sum all together
    console.log(assetData);
    let totalPrice = new BigNumber(0);

    if (assetData.coverImage && assetData.coverImage.byteLength > 0 ) {
      let tx = bundlr?.createTransaction(assetData.coverImage!, {tags: [{name: "Content-Type", value: assetData.typeCoverImage!}]});
      totalPrice = totalPrice.plus((await bundlr?.utils.getPrice(currency as string, tx!.size))!);
      console.log("dataCoverImage", totalPrice);
    }

    if (assetData.dataEncrypted && assetData.dataEncrypted.byteLength > 0 ) {
      let tx = bundlr?.createTransaction(assetData.dataEncrypted!, {tags: [{name: "Content-Type", value: assetData.typeEncrypted!}]});
      totalPrice = totalPrice.plus((await bundlr?.utils.getPrice(currency as string, tx!.size))!);
      console.log("dataEncrypted", totalPrice);
    }

    if (assetData.dataPublic && assetData.dataPublic.byteLength > 0) {
      let tx = bundlr?.createTransaction(assetData.dataPublic!, {tags: [{name: "Content-Type", value: assetData.typePublic!}]});
      totalPrice = totalPrice.plus((await bundlr?.utils.getPrice(currency as string, tx!.size))!);
      console.log("dataPublic", totalPrice);
    }

    const asset = formatAsset(assetData);
    let tx = bundlr?.createTransaction(JSON.stringify(asset), {tags: [{name: "Content-Type", value: "application/json"}]});
    totalPrice = totalPrice.plus((await bundlr?.utils.getPrice(currency as string, tx!.size))!);
    console.log("assets", totalPrice);

    setPrice(totalPrice);
  }

  const checkBalance = async (): Promise<void> => {
    let bal = await bundlr?.getBalance(bundlr.address);
    setBalance(bal);
  }

  const signTransaction = async () => {
    try {

      let asset = formatAsset(assetData);

      if (assetData.coverImage && assetData.coverImage.byteLength > 0 ) {
        let tx = bundlr?.createTransaction(assetData.coverImage!, {tags: [{name: "Content-Type", value: assetData.typeCoverImage!}]});
        await tx?.sign();
        const res = await tx?.upload();
        asset.urlCoverImage = res.data.id;
      }
      
      if (assetData.dataEncrypted && assetData.dataEncrypted.byteLength > 0 ) {
        let tx = bundlr?.createTransaction(assetData.dataEncrypted!, {tags: [{name: "Content-Type", value: assetData.typeEncrypted!}]});
        await tx?.sign();
        const res = await tx?.upload();
        asset.urlEncrypted = res.data.id;
      }
      
      if (assetData.dataPublic && assetData.dataPublic.byteLength > 0) {
        let tx = bundlr?.createTransaction(assetData.dataPublic!, {tags: [{name: "Content-Type", value: assetData.typePublic!}]});
        await tx?.sign();
        const res = await tx?.upload();
        asset.urlPublic = res.data.id;
      }

      let tx = bundlr?.createTransaction(JSON.stringify(asset), {tags: [{name: "Content-Type", value: "application/json"}]});
      await tx?.sign();
      const res = await tx?.upload();  
      let arid_ = res.data.id;

      setArid(arid_);
      // save to local storage
      localStorage.setItem("arid", arid_);
    } catch (e: any) {
      message.error(`Error: Failed to upload - ${e.data?.message || e.message}`);
    }

  }
  
  const releaseStorage = async () => {
    localStorage.setItem("arid", "");
    setArData(undefined);
    setArid(undefined);
    setPrice(null);
    setAssetData({dataDecrypted: Buffer.from(""), dataEncrypted: Buffer.from("")});
  }

  const getRedirectedArUri = async (): Promise<void>  => {
    setArData(await fetchDisplayContent(arid!));
  }

  const providerValue = {
    bundlr: bundlr,
    balance: balance,
    price: price,
    arid: arid,
    arData: arData,
    assetData: assetData,
    initBundlr: initBundlr,
    fund: fund,
    setAssetData: setAssetData,
    checkPrice: checkPrice,
    signTransaction: signTransaction,
    releaseStorage: releaseStorage
  }

  return (
    <StorageContext.Provider value={providerValue}>
        {children}
    </StorageContext.Provider>
)
}

export default StorageProvider;