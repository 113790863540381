import { Signer } from 'ethers';
import { createContext } from 'react';
import ERCUtils from './erc/utils';
import { IWeb3Context, PermSig } from './type';

const Web3Context = createContext<IWeb3Context>({
    signer: undefined,
    getSigner: async () => { return ERCUtils.getSigner() },
    connect: async () => {},
    connectedWallet: '',
    selectTargetNetwork: async () => {},
    isTargetNetwork: async () => {return false},
    isPolygon: async () => {},
    isMumbai: async () => {},
    isRopsten: async () => {},
    network: 0,
    targetNetwork: 0,
    hasMetamask: false
});

export default Web3Context;
