/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IPaidMintable, IPaidMintableInterface } from "../IPaidMintable";

const _abi = [
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "copyHash",
        type: "bytes32",
      },
    ],
    name: "getMintCount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "copyHash",
        type: "bytes32",
      },
    ],
    name: "getValidationInfo",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "feeToken",
            type: "address",
          },
          {
            internalType: "uint64",
            name: "duration",
            type: "uint64",
          },
          {
            internalType: "bool",
            name: "fragmented",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "mintAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "extendAmount",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "requiredERC721Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "limit",
            type: "uint256",
          },
          {
            internalType: "uint64",
            name: "start",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "time",
            type: "uint64",
          },
        ],
        internalType: "struct IPaidMintable.ValidationInfo",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "copyHash",
        type: "bytes32",
      },
      {
        internalType: "uint64",
        name: "duration",
        type: "uint64",
      },
    ],
    name: "isExtendable",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "copyHash",
        type: "bytes32",
      },
      {
        internalType: "uint64",
        name: "duration",
        type: "uint64",
      },
    ],
    name: "isMintable",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "copyHash",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "ruleData",
        type: "bytes",
      },
    ],
    name: "setupRule",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IPaidMintable__factory {
  static readonly abi = _abi;
  static createInterface(): IPaidMintableInterface {
    return new utils.Interface(_abi) as IPaidMintableInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IPaidMintable {
    return new Contract(address, _abi, signerOrProvider) as IPaidMintable;
  }
}
