/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { ICopy, ICopyInterface } from "../ICopy";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "copyHash",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "expiry",
        type: "uint64",
      },
    ],
    name: "Create",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Destroy",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "expiry",
        type: "uint64",
      },
    ],
    name: "Extend",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "copyHash",
        type: "bytes32",
      },
    ],
    name: "PauseMintableRule",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Revoke",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "copyHash",
        type: "bytes32",
      },
      {
        components: [
          {
            internalType: "address",
            name: "mintable",
            type: "address",
          },
          {
            internalType: "enum IERC721Copy.Statement",
            name: "statement",
            type: "uint8",
          },
          {
            internalType: "bool",
            name: "transferable",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "updatable",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "revokable",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "extendable",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "creatorId",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "mintInfoAdditional",
            type: "bytes",
          },
        ],
        indexed: false,
        internalType: "struct IERC721Copy.MintInfo",
        name: "mintInfo",
        type: "tuple",
      },
    ],
    name: "SetMintableRule",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "tokenUri",
        type: "string",
      },
    ],
    name: "Update",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "copyHash",
        type: "bytes32",
      },
      {
        internalType: "uint64",
        name: "duration",
        type: "uint64",
      },
    ],
    name: "create",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "creatorOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "destroy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "expireAt",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "uint64",
        name: "duration",
        type: "uint64",
      },
    ],
    name: "extend",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "creatorId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "getCopyByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "creatorId",
        type: "uint256",
      },
    ],
    name: "getCopyCount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "creatorId",
        type: "uint256",
      },
    ],
    name: "getCopyHashes",
    outputs: [
      {
        internalType: "bytes32[]",
        name: "",
        type: "bytes32[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getCopyInfo",
    outputs: [
      {
        components: [
          {
            internalType: "string",
            name: "copyURI",
            type: "string",
          },
          {
            internalType: "bytes32",
            name: "copyHash",
            type: "bytes32",
          },
          {
            internalType: "uint64",
            name: "expireAt",
            type: "uint64",
          },
        ],
        internalType: "struct ICopy.CopyInfo",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getCreatorContract",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "copyHash",
        type: "bytes32",
      },
    ],
    name: "getMintInfo",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "mintable",
            type: "address",
          },
          {
            internalType: "enum IERC721Copy.Statement",
            name: "statement",
            type: "uint8",
          },
          {
            internalType: "bool",
            name: "transferable",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "updatable",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "revokable",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "extendable",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "creatorId",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "mintInfoAdditional",
            type: "bytes",
          },
        ],
        internalType: "struct IERC721Copy.MintInfo",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "copyId",
        type: "uint256",
      },
    ],
    name: "getMintableRule",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getStatement",
    outputs: [
      {
        internalType: "enum IERC721Copy.Statement",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "collector",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "creatorId",
        type: "uint256",
      },
    ],
    name: "hasValidCopy",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "isExpired",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "isExtendable",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "isRevokable",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "isTransferable",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "isUpdatable",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "copyHash",
        type: "bytes32",
      },
    ],
    name: "pauseMintableRule",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "revoke",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "mintable",
            type: "address",
          },
          {
            internalType: "enum IERC721Copy.Statement",
            name: "statement",
            type: "uint8",
          },
          {
            internalType: "bool",
            name: "transferable",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "updatable",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "revokable",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "extendable",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "creatorId",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "mintInfoAdditional",
            type: "bytes",
          },
        ],
        internalType: "struct IERC721Copy.MintInfo",
        name: "mintInfo",
        type: "tuple",
      },
      {
        internalType: "bytes",
        name: "mintableInitData",
        type: "bytes",
      },
    ],
    name: "setMintableRule",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "collector",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "creatorId",
        type: "uint256",
      },
    ],
    name: "tokenCounter",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "update",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class ICopy__factory {
  static readonly abi = _abi;
  static createInterface(): ICopyInterface {
    return new utils.Interface(_abi) as ICopyInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): ICopy {
    return new Contract(address, _abi, signerOrProvider) as ICopy;
  }
}
