
export const ROUTE_BASE = '/';

export const ROUTE_CREATE = '/create';

export const ROUTE_FAUCET = '/faucet'
export const ROUTE_FAQS = '/faqs'

export const ROUTE_MY_ASSETS = '/assets'
export const ROUTE_MY_CREATIONS = '/assets/creations'
export const ROUTE_MY_COPIES = '/assets/copies'
export const ROUTE_MY_NAMES = '/assets/names'

export const ROUTE_ALL_ASSETS = '/explore'
export const ROUTE_ALL_CREATIONS = '/explore/creations'
export const ROUTE_ALL_COPIES = '/explore/copies'

export const ROUTE_CREATION_ROOT = '/creations/'
export const ROUTE_CREATION = ROUTE_CREATION_ROOT + ':creation'
export const ROUTE_COPY_ROOT = '/copies/'
export const ROUTE_COPY = ROUTE_COPY_ROOT + ':copy'

export const ROUTE_NAME = '/name'