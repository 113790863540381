import LitJsSdk from "@lit-protocol/sdk-browser";
import { getContracts } from "../../../common/contracts";
import { IEncryptedData } from "./type";

const client = new LitJsSdk.LitNodeClient()
const CHAIN_MAPPING: Record<number, string> = {
  137: 'polygon',
  80001: 'mumbai'
}

const getAccessControlConditions = (tokenId: string, chainId: number) => {
  const contracts = getContracts(chainId);
  return [
    {
      conditionType: "evmContract",
      contractAddress: contracts?.copy,
      functionName: "hasValidCopy",
      functionParams: [':userAddress', tokenId],
      functionAbi: {
        type: "function",
        stateMutability: "view",
        outputs: [
          {
            type: "bool",
            name: "",
            internalType: "bool",
          },
        ],
        name: "hasValidCopy",
        inputs: [
          {
            type: "address",
            name: "collector",
            internalType: "address",
          },
          {
            type: "uint256",
            name: "creatorId",
            internalType: "uint256",
          },
        ],
      },
      chain: CHAIN_MAPPING[chainId],
      returnValueTest: {
        key: "",
        comparator: '=',
        value: 'true'
      }
    },
    {operator: "or"},
    {
      conditionType: "evmBasic",
      contractAddress: contracts?.creator,
      standardContractType: "ERC721",
      chain: CHAIN_MAPPING[chainId],
      method: "ownerOf",
      parameters: [tokenId],
      returnValueTest: {
        comparator: "=",
        value: ":userAddress",
      },
    },
  ]
}
  
  // condition for testing
  const getAccessControlConditionsT = (tokenId: string, chainId: number) => [
    {
      contractAddress: "",
      standardContractType: "",
      chain: "ethereum",
      method: "eth_getBalance",
      parameters: [":userAddress", "latest"],
      returnValueTest: {
        comparator: ">=",
        value: "10", // 0.000001 ETH
      },
    }
  ]

class Lit {
  litNodeClient: any

  async connect() {
    await client.connect()
    this.litNodeClient = client
  }

  async encryptFile(file: Blob | File, tokenId: string, chainId: number): Promise<IEncryptedData> {
    if (!this.litNodeClient) {
      await this.connect()
    }

    const authSig = await LitJsSdk.checkAndSignAuthMessage({chain: CHAIN_MAPPING[chainId] })

    const { encryptedFile, symmetricKey } = await LitJsSdk.encryptFile({file: file})

    const encryptedSymmetricKey = await this.litNodeClient.saveEncryptionKey({
      unifiedAccessControlConditions: getAccessControlConditions(tokenId, chainId),
      symmetricKey,
      authSig,
      chain: CHAIN_MAPPING[chainId],
    })
    
    // this.decryptFile(encryptedFile, LitJsSdk.uint8arrayToString(encryptedSymmetricKey, "base16"), tokenId, chainId);

    return {
      encryptedFile: encryptedFile,
      encryptedSymmetricKey: LitJsSdk.uint8arrayToString(encryptedSymmetricKey, "base16"),
      accessControlConditions: getAccessControlConditions(tokenId, chainId)
    }
  }

  async decryptFile(encryptedFile: Blob| File, encryptedSymmetricKey: string, tokenId: string, chainId: number) {
    if (!this.litNodeClient) {
      await this.connect()
    }
    const authSig = await LitJsSdk.checkAndSignAuthMessage({ chain: CHAIN_MAPPING[chainId]  })
    const symmetricKey = await this.litNodeClient.getEncryptionKey({
      unifiedAccessControlConditions: getAccessControlConditions(tokenId, chainId),
      toDecrypt: encryptedSymmetricKey,
      chain: CHAIN_MAPPING[chainId],
      authSig
    })
    const decryptedFile = await LitJsSdk.decryptFile({
      file: encryptedFile, 
      symmetricKey: symmetricKey
    });
    return { decryptedFile }
  }
}

export default new Lit()
