
interface Faq {
  title: string;
  paragraph: string;
  list?: string[];
}

export const FAQS: Faq[] = [
  {
    title: "What is Copy Distributor?",
    paragraph: `Copy distributor is a decentralized protocol that manages Copyright Distribution for the creators and collectors. 
      The creation of NFTs under this protocol requires a signature from the creator to transfer the full copyright to the holder 
      of the Creator NFT, who then have the authority to setup minting rules to enable the creation Copy NFTs. Copy NFTs can be created
      with their own corresponding properties, e.g. transferable, updatable, etc, and the corresponding mint/extend rules, i.e. token 
      payment, duration, etc.`
  },
  {
    title: "How are Copyrights Handled?",
    paragraph: `Copyrights are first transfered from the creator (Author) to the holder of the Creator NFT Token. The holder of the Creator 
      NFT token can then futher distribute the copyright to the collectors by enabling the minting of Copy NFT Tokens with the corresponding
      copyrights transfer, i.e. Collect, Use, Distribute, Modify`
  },
  {
    title: "What are the Copyright Types?",
    paragraph: "The currently supported copyright types are as follows, the later includes all of the former copyrights: ",
    list: [
      "COLLECT: Holder can hold the Content",
      "USE: Holders can use the Content",
      "MODIFY: Holders can modify the Content",
      "DISTRIBUTE: Holders can distribute the Content"
    ]
  },
  {
    title: "What are the Supported Mint Properties?",
    paragraph: "The currently supported copyright mint properties are as follows",
    list: [
      "STATEMENT    (Number) : The Copyright Type to be transferred to the Copy NFT holder",
      "TRANSFERABLE (Boolean): Specifies whether the Copy NFT holder is allowed to transfer the Copy NFT Token",
      "UPDATABLE    (Boolean): Specifies whether the Copy NFT holder is allowed to update the Copy NFT Token to the latest tokenURL in the Creator Token",
      "EXTENDABLE   (Boolean): Specifies whether the Copy NFT holder is allowed to extend the expiry date of the NFT Token",
      "REVOKABLE    (Boolean): Specifies whether the Creator NFT holder is allowed to revoke ownership of the Copy NFT Token"
    ]
  },
  {
    title: "What are the Supported Mint/Extend Rules?",
    paragraph: "The mint/extend rules are as follows",
    list: [
      'FEETOKEN (string): The address of the token required for pay minting fee. Zero Address denotes native token. Non-zero addresses denote any ERC20 compliant token',
      'MINT FEE (number): The fee required for minting',
      'EXTEND FEE (number): The fee required for extending the expiry',
      'REQUIRED ERC721 TOKEN (string): The address of the NFT that collects are required to have positive balance of',
      'DURATION (number): The duration that the newly minted Copy NFT will remain valid',
      'START (number): The start timestamp that minting event is enabled (When minting is open)',
      'TIME (number): The time period of the minting event. (How long minting will be open)',
      'LIMIT (number): The maximum number of Copy NFT Tokens with a particular set of properties for minting'
    ]
  },
  {
    title: "Is this platform decentralized?",
    paragraph: `This platform is decentralized. The frontend runs without any backend and it serves as the gateway to the Copy Distributor Smart Contracts and decentralized storage facilities, i.e. ARWEAVE. 
      The source code for both the frontend and the smart contracts will be made open sourced.`
  },
  {
    title: "What are the Supported Storage Methods?",
    paragraph: "Only ARWEAVE is supported in the frontend. We plan to support more storage methods such as IPFS or Web2 urls in the future."
  },
  {
    title: "What are the Supported Storage Content-Types?",
    paragraph: "Only Image/Png, Image/Jpg and Image/Jpeg are supported in the frontend. We plan to support also music and videos in the future."
  },
  {
    title: "Future Road Map",
    paragraph: "",
    list: [
      "Testing network migration from Ropsten to Goreli as Ropsten will be shutdown in October 2022",
      `Implement duration fragmentation. Duration fragmentation enables the Collector to select of any abitrary token duration 
      instead of a fixed preset duration.`,
      'Supports more blockchains'
    ]
  }
]