import { ChangeEventHandler, FC, useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout";

import { Input, Pagination, Select } from "antd";
import { fetchArtNameServiceNames } from "../../controllers/api";
import DomainNamesView from "../../components/DomainNamesView";
import { FetchAssetsResult } from "../../components/DomainNamesView/type";

const NamePage: FC = ({
}) => {

    const [name, setName] = useState<string>();
    const [sortOrder, setSortOrder] = useState<string>("Latest");
    
    const [assets, setAssets] = useState<FetchAssetsResult|undefined>();
    const pageSize = 50;
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        fetchArtNameServiceAssets(pageNumber, pageSize, sortOrder, undefined).then((result) => {
          setTotal(result.count);
          setAssets(result);
        });
    }, []);

    
    const fetchArtNameServiceAssets = async (pageNumber: number, pageSize: number, order: string, domainName?: string) => {
      // call didhub api to fetch all name service assets
      const artDomainNameData = await fetchArtNameServiceNames(order == "Latest", pageNumber, pageSize, domainName);

      return {
        assets: artDomainNameData?.data,
        count: artDomainNameData?.total
      };
    }

    const onSearchNameHandle = async (value: string) => {
        if (!name || name && name.length > 2) {
          const assets = await fetchArtNameServiceAssets(pageNumber, pageSize, sortOrder, name);
          setTotal(assets.count);
          setAssets(assets);
        }
    }

    // TODO refactor to custom hook?
    // const onChangeTextHandle: ChangeEventHandler<HTMLInputElement> = (e) => {
    //   const { value: inputValue } = e.target;
    //   if (inputValue.length > 42) return;
    //   const reg = /^[a-fA-F0-9]+$/;
    //   if (inputValue === '' || inputValue === '0' || inputValue === '0x' || inputValue.slice(0,2) === "0x" && reg.test(inputValue.slice(2, inputValue.length))){
    //     setAddress(inputValue);
    //   }
    // }
    
    const onChangeNameHandle: ChangeEventHandler<HTMLInputElement> = (e) => {
      const { value: inputValue } = e.target;
      setName(inputValue);
    }

    const onChangeSortOrderHandle = async (value: string) => {
      if (value !== sortOrder) {
        setSortOrder(value);
        const assets = await fetchArtNameServiceAssets(pageNumber, pageSize, value, name);
        setTotal(assets.count);
        setAssets(assets);
      }
    }
    
    const onPageChange = async (page: number) => {
      setPageNumber(page-1);
      const assets = await fetchArtNameServiceAssets(page-1, pageSize, sortOrder, name);
      setTotal(assets.count);
      setAssets(assets);
    }
    
    const onButtonClick = () => {
      document.getElementsByClassName('domain-display-root')[0].scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }

    let options = ["Latest", "Oldest"];
    
    return (
        <DefaultLayout >
            <div className="name">
                <div className="name-header">
                  <div className='name-header-input'>
                    {/* Dropdown List */}
                    <Select
                      className="name-header-input-selector"
                      dropdownClassName='display-item'
                      value={sortOrder}
                      labelInValue={false}
                      onChange={onChangeSortOrderHandle}
                    >
                    {options.map((o) => 
                      <Select.Option 
                        key={o}
                        className='display-list-item' 
                        value={o} >
                        {o}
                      </Select.Option>)}
                    </Select>

                    {/* Text Input */}
                    <Input.Search
                      onSearch={onSearchNameHandle} 
                      className="transfer-input" 
                      value={name}
                      placeholder="Enter Name to Search" 
                      enterButton
                      onChange={onChangeNameHandle}/>
                  </div>
                </div>
                <DomainNamesView
                  assetResult={assets}
                  imageWidth={150}
                  imageHeight={205}
                />
                <div className="scroll-button">
                  <img src={"/BackTop.png"} width={50} height={50} onClick={onButtonClick}/>
                </div>
                <div className="position-wrapper">
                  <div className="paginator">
                    <Pagination defaultCurrent={pageNumber+1} pageSize={pageSize} onChange={onPageChange} total={total} size={"small"} />
                  </div>                  
                </div>
              </div>
        </DefaultLayout>
    )
};

export default NamePage;