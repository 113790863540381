import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { message } from 'antd';
import { FC, useContext, useState } from 'react';
import { fetchEncryptedContent } from '../../controllers/api';
import { ArData, SelectorData } from '../../controllers/web3/storage/type';
import AssetView from '../AssetView';
import { selectData } from '../../controllers/web3/storage/assetFormater';
import { IWeb3Context } from '../../controllers/web3/type';
import Web3Context from '../../controllers/web3/context';

interface PropsType {
  tokenId: string
  className?: string
  data?: ArData,
  width: number | string,
  height?: number | string,
  controls?: boolean
  hoverEffects?: boolean
}

/**
 * A class for layered display of contents. 
 * 
 * @param className
 * @returns 
 */
const AssetViewLayered: FC<PropsType> = ({
  tokenId,
  className,
  data,
  width,
  height,
  controls = true,
  hoverEffects = false
}) => {
  
  const { targetNetwork, isTargetNetwork} = useContext<IWeb3Context>(Web3Context);
  const [isRevealed, setIsRevealed] = useState<boolean>(false);
  const [decryptedData, setDecryptedData] = useState<SelectorData>();
  
  if (!data) return <div style={{width: width, height: height}}></div>
  
  // for early version
  if ( ! data?.format) {
    return <AssetView className={className} data={selectData(data, 0)} width={width} height={height} controls={controls} hoverEffects={hoverEffects}/>
  }

  const revealHandler = async () => {
    if (isRevealed) {
      setIsRevealed(false);
      return;
    }

    if (decryptedData) {
      setIsRevealed(true);
    }

    if (data?.urlEncrypted && data?.dataEncryptionKey){
      // fetch encrypted content
      let decryptedFile;
      try {
        await isTargetNetwork();
        decryptedFile = await fetchEncryptedContent(data?.urlEncrypted, data?.dataEncryptionKey, tokenId, targetNetwork);
        const blob = new Blob([decryptedFile?.decryptedFile]);
        const url = URL.createObjectURL(blob);
        setDecryptedData({
          url: url,
          type: data.typeEncrypted!
        });
        setIsRevealed(true);
      } catch {
        message.info("Only Creator Token Owner or Non-Expired Copy Token Owner Can Decrypt The Hidden Contents");
      }
    }
  }

  return (
    <div className='asset-view-layered'>
      {
        isRevealed && decryptedData ?
          <AssetView className={className} data={decryptedData} width={width} height={height} controls={controls} hoverEffects={hoverEffects}/> :
          <AssetView className={className} data={selectData(data, 1)} width={width} height={height} controls={controls} hoverEffects={hoverEffects}/>
      }
      {/* Icon for toggle reveal status */}
      <div className='asset-view-layered-reveal-icon' onClick={revealHandler}>{isRevealed ? <EyeInvisibleFilled/>:<EyeFilled/>}</div>
    </div>
  )
};

export default AssetViewLayered;
