import { ChangeEventHandler, FC, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Contracts from "../../controllers/contracts";
import { IWeb3Context } from "../../controllers/web3/type";
import Web3Context from "../../controllers/web3/context";
import { CreatorFullViewStructOutput } from "../../controllers/contracts/typechain-types/Helper";

import { Col, Row, Typography, Divider, message, Input } from 'antd';
import DefaultLayout from "../../components/Layout";

import { resolveBlockExplorerUrl } from "../../utils";
import MintableItem from "../../components/MintableItem";
import { ROUTE_ALL_COPIES, ROUTE_ALL_CREATIONS, ROUTE_CREATE } from "../../common/route";
import { BigNumber } from "ethers";
import { getContracts } from "../../common/contracts";
import { getNFTOwner } from "../../controllers/contracts/utils";
import NormalButton from "../../components/Buttons/NormalBtn";
import { BlockOutlined } from "@ant-design/icons";
import useAssetDisplay from "../../hooks/useAssetDisplay";
import AssetView from "../../components/AssetView";
import AssetViewLayered from "../../components/AssetViewLayered";

const CreationExplorePage: FC = ({
}) => {

  const { connect, isTargetNetwork, connectedWallet, targetNetwork, getSigner } = useContext<IWeb3Context>(Web3Context);
  
  let params: any = useParams();
  let history = useHistory();

  const [creatorInfo, setCreatorInfo] = useState<CreatorFullViewStructOutput>();
  const [error, setError] = useState<boolean>();
  const [isCreator, setIsCreator] = useState<boolean>();
  const [transferAddress, setTransferAddress] = useState<string>();

  const data = useAssetDisplay(creatorInfo?.creation?.tokenURI!);

  useEffect(() => {
    if (!connectedWallet) {
        connect();
    }
    isTargetNetwork();
  }, [])

  useEffect(() => {
    fetchInfoOnChain();
    const intervalId = setInterval(fetchInfoOnChain, 5000);
    return () => clearInterval(intervalId);
  }, [])

  const fetchInfoOnChain = async () => {
    fetchCreationInfomation().then(
      (res)=>setCreatorInfo(res)
    );
    isTokenCreator(params.creation).then(
      (v) => setIsCreator(v)
    ).catch((err)=>{console.log(err)})
  }
  
  const fetchCreationInfomation = async () => {
    try {
      let contracts = await Contracts(await getSigner(), targetNetwork!);
      return await contracts.helper.getMintInfoByCreator(params.creation);
    } catch (error: any) {
      if( error.code == "CALL_EXCEPTION") {
        setError(true);
      }
    }
  }
  
  const isTokenCreator = async (creatorId: BigNumber) => {
    let creatorAddress = getContracts(targetNetwork)?.creator;
    let signer = await getSigner();
    let owner = await getNFTOwner(signer, creatorAddress!, creatorId);
    return owner == (await signer.getAddress());
  }

  const onCopiesClickHandler = () => {
    history.push(ROUTE_ALL_COPIES + "?creatorId=" + params.creation); // TODO change
  }

  const onClickUpdateHandle = async () => {
    history.push({
      pathname: ROUTE_CREATE,
      search: "?creatorId=" + params.creation
    })
  }

  const onClickBurnHandle = async () => {
    if(!await isTargetNetwork()) return;
    let signer = await getSigner();
    let contracts = await Contracts(signer, targetNetwork);
    const tx = await contracts.creator.burn(params.creation);
    await tx.wait();
    message.success("Token Destroyed");
  }


  const onChangeTextHandle: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value: inputValue } = e.target;
    if (inputValue.length > 42) return;

    const reg = /^[a-fA-F0-9]+$/;
    if (inputValue === '' || inputValue === '0' || inputValue === '0x' || inputValue.slice(0,2) === "0x" && reg.test(inputValue.slice(2, inputValue.length))){
      setTransferAddress(inputValue);
    }
  }

  const onClickTransferHandle = async () => {
    if(!await isTargetNetwork()) return;
    let signer = await getSigner();
    let contracts = await Contracts(signer, targetNetwork);
    const tx = await contracts.creator.transferFrom(await signer.getAddress(), transferAddress!, params.creation);
    await tx.wait();
    
    message.info("Transfer Successful!");
  }
    
  const maxCell = 24;

  return <DefaultLayout>
          { creatorInfo &&
            <div className="creation-root">
              <Row gutter={[16, 24]}>
              
              <Col className="gutter-row" xs={maxCell} sm={maxCell} lg={maxCell} xl={10} >
                <div className='creation-image-container'>
                  <AssetViewLayered tokenId={params.creation} className="creation-image" data={data} width={'100%'} height={'auto'} />
                </div>
              </Col>

              <Col className="gutter-row" xs={maxCell} sm={maxCell} lg={maxCell} xl={14}>
                <div className="creation-info">
                    <Typography.Title level={4}>
                      Creator Token ID: {params.creation}
                    </Typography.Title>
                    <Typography.Title level={4}>
                      {"Owner: "}
                      <a href={resolveBlockExplorerUrl(creatorInfo?.creation.holder!, targetNetwork)} target={"_blank"} rel="noreferrer">
                        <BlockOutlined/>
                        <div>
                          <a href={ROUTE_ALL_CREATIONS + "?address=" + creatorInfo?.creation.holder}>{creatorInfo?.creation.holder}</a>
                        </div>
                      </a>
                    </Typography.Title>
                    <Typography.Title level={4}>
                      {"Arweave TxHash: "} 
                      <a href={data?.url} target={"_blank"} rel="noreferrer">{creatorInfo?.creation.tokenURI}</a>
                    </Typography.Title>
                    <Typography.Title level={4}>
                      {"Copies: "}
                      {creatorInfo?.creation.copies.gt(0) ? 
                        <a onClick={onCopiesClickHandler} target={"_blank"} rel="noreferrer">{creatorInfo?.creation.copies.toString()}</a> :  
                        creatorInfo?.creation.copies.toString()}
                    </Typography.Title>
                </div>
              </Col>

              { isCreator && <Divider className="creation-div"/>}

              { isCreator && // Creator Panel
                <Col className="gutter-row" xs={maxCell} sm={maxCell} lg={maxCell} xl={24}>
                  
                  <Typography.Title level={4}>
                      You are the creator !
                  </Typography.Title>

                  <div className="creation-panel-div">
                  <Typography.Title level={5} className="creation-panel-text"> 
                    {"Update the token URI"}
                  </Typography.Title>
                    <div className="creation-panel-button">
                      <NormalButton
                        placeholder={"Update Token"}
                        onClick={onClickUpdateHandle}/>
                    </div>
                  </div>

                  <div className="creation-panel-div">
                      <Input className="transfer-input" value={transferAddress} placeholder="To Address" onChange={onChangeTextHandle}/>
                      <NormalButton
                        placeholder={"Transfer Token"} 
                        disabled={transferAddress?.length !== 42}
                        onClick={onClickTransferHandle}/>
                  </div>

                  <div className="creation-panel-div">
                    <Typography.Title level={5} className="creation-panel-text"> 
                      {"Destroy the token. All copy minting will cease"}
                    </Typography.Title>
                    <div className="creation-panel-button">
                      <NormalButton
                        placeholder={"Burn Token"}
                        onClick={onClickBurnHandle}/>
                    </div>
                  </div>

                </Col> 
              }

              { creatorInfo?.creation.rules.gt(0) &&
                <Divider className="creation-div" orientation="center"> Mintable Rules Total: {creatorInfo?.creation.rules.toString()}</Divider>
              }
              { creatorInfo &&
                [...Array(creatorInfo.creation.rules.toNumber()).keys()].map((i) => {
                  return <MintableItem key={creatorInfo.mintable[i].copyHash} mintable={creatorInfo.mintable[i]}/>
                })
              }

            </Row>
          </div>}
          {error && 
            <div className="creation-error">
              <Typography.Title>{"Token " + params.creation + " does not exist"}</Typography.Title>
            </div>
          }
        </DefaultLayout>
}

export default CreationExplorePage