import { useState, useEffect } from 'react';
import { fetchContent, fetchHead, fetchDisplayContent } from '../../controllers/api';
import { ArData } from '../../controllers/web3/storage/type';
import * as mmb from 'music-metadata-browser';
import { extractMusicMeta } from '../../controllers/data';

const useAssetDisplay = (
  url: string
) => {
  
  const [ data, setData ] = useState<ArData>();

  useEffect(() => {
    if (url) {
      fetchDisplayContent(url).then((r)=>setData(r));
      // fetchNFTData(url).then(
      //   (res)=>{
      //     let contentType = res?.headers.get("Content-Type")!.toString()!         
      //     if (contentType && contentType.split('/')[0] == "audio") {
      //       // resolve meta
      //       fetchContent(url).then((res2)=>{
      //         res2?.blob().then((blob)=>{
      //           mmb.parseBlob(blob).then((metadata)=>{
      //             let meta = extractMusicMeta(metadata.common);
      //             setData({
      //               url: res?.url!,
      //               type: contentType,
      //               meta: meta
      //             });
      //           })
      //         })
      //       });

      //     } else {
      //       setData({
      //         url: res?.url!,
      //         type: contentType
      //       });
      //     }
      //   }
      // );
    }
  }, [url])

  const fetchNFTData = async (url: string) => {
      return (await fetchHead(url));
  }

  return data;
}

export default useAssetDisplay;