import { createContext } from 'react';
import { IStorageContext } from './type';
import BigNumber from "bignumber.js";

const StorageContext = createContext<IStorageContext>({
    // connect: async () => {},
    bundlr: undefined,
    price: undefined,
    balance: undefined,
    arid: undefined,
    arData: undefined,
    assetData: {dataDecrypted: Buffer.from("")},
    initBundlr: async () => {},
    fund: async () => {},
    setAssetData: () => {},
    checkPrice: async () => {},
    signTransaction: async () => {},
    releaseStorage: async () => {}
});

export default StorageContext;
