import { BigNumber, Contract } from "ethers";
import {BigNumber as BigJs} from 'bignumber.js';
import { getNow } from "../controllers/contracts/utils";
import moment from 'moment';
import { getTokens } from "../common/tokens";
import { getContracts } from "../common/contracts";
import { NETWORKS } from '../common/networks';

interface MintOpeningStates {
  state: number,
  description: string
}

export const resolveCopyright = (copyright: number) => {
  switch (copyright) {
    case 0:
      return "Collect";
    case 1:
      return "Use";
    case 2:
      return "Modify";
    case 3:
      return "Distribute";
  }
}

export const resolveExpiry = (expiry: BigNumber, format: string = "YYYY-MM-DD"): string => {
  if ( expiry.lte(getNow()) ) {
    return "Expired";
  } else if (expiry.gte(999999999999999)) {
    return "No Expiry Date";
  } else {
    let utcTime = moment.utc(expiry.toNumber() * 1000).format(format);
    return "Expire: " + utcTime;
  }
}

export const resolveMintOpening = (start: BigNumber, time: BigNumber): MintOpeningStates => {
  let now = getNow();
  if (start.gt(now)){
    let description = start.gte(999999999999999) ? "Minting Disabled" : 
        "Minting Open At: " + moment.utc(start.mul(BigNumber.from(1000)).toNumber()).format("YYYY-MM-DD");
    return {
      state: -1,
      description: description
    };
  } else if ( start.add(time).lte(now) ) {
    return {
      state: 1,
      description: "Minting Closed"
    };
  } else {
    let description = start.add(time).gte(999999999999999) ? "Minting Open Forever" : 
        "Minting Open Until " + moment.utc(start.add(time).mul(BigNumber.from(1000)).toNumber()).format("YYYY-MM-DD");
    return {
      state: 0,
      description: description
    }
  }
  
}

export const getDecimalsString = (zeros: number)=>{
  let str = "1";
  for (let i = 0 ; i < zeros ; i++ ) {
    str+="0";
  }
  return str;
}

export const multiplyAmountWithDecimals = (value: number, decimals: number) => {
  let bjsValue = new BigJs( value.toString())
  let bjsDec = new BigJs(getDecimalsString(decimals));
  let amount =  bjsValue.multipliedBy(bjsDec);
  return BigNumber.from(amount.toFixed());
}

export const divideAmountByDecimals = (value: BigNumber, decimals: number) => {
  let bjsValue = new BigJs( value.toString())
  let bjsDec = new BigJs(getDecimalsString(decimals));
  let amount =  bjsValue.dividedBy(bjsDec);
  return amount.toFixed();
}

export const resolveMintFee = (mintFee: BigNumber, tokenAddress: string, network: number): string  => {
  if (mintFee.eq(0)){
    return "FREE";
  }

  // check is mock FT token
  if ( tokenAddress == getContracts(network)!.test.mockFT ) {
    return divideAmountByDecimals(mintFee, 18) + " MUSDT";
  }

  const tokenInfo = getTokens(network);
  const tokens = Object.keys(tokenInfo!);

  let result = "Unknown Token";
  tokens.forEach((value: string) => {
        if (tokenInfo![value].address.toLowerCase() == tokenAddress.toLowerCase()) {
          result = divideAmountByDecimals(mintFee, tokenInfo![value].decimals) + " " + value.toUpperCase();
        }
    }
  )
  return result;
}


export const resolveDuration = (duration: BigNumber) => {
  if (duration.gt(999999999999999)) return "Forever";
  return moment.utc((getNow() + duration.toNumber() ) * 1000).local().format("YYYY-MM-DD HH:mm:ss");
}

export const resolveBlockExplorerUrl = (address: string, targetNetwork: number) => {
  const network = NETWORKS[targetNetwork];
  return network.blockExplorerUrl + "address/" + address;
}