import { ChangeEventHandler, FC, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { IWeb3Context } from "../../controllers/web3/type";
import Web3Context from "../../controllers/web3/context";
import { Input, Col, Row, Typography, Divider, message } from 'antd';
import DefaultLayout from "../../components/Layout";
import { CopyViewStructOutput } from "../../controllers/contracts/typechain-types/Helper";
import {fetchDisplayContent } from "../../controllers/api";
import Contracts from "../../controllers/contracts";
import { ROUTE_ALL_COPIES, ROUTE_CREATION_ROOT } from "../../common/route";
import MintableItem from "../../components/MintableItem";

import { resolveBlockExplorerUrl, resolveDuration, resolveExpiry, resolveMintFee } from "../../utils";
import { getContracts } from "../../common/contracts";
import { getFTAllowance, getNFTOwner } from "../../controllers/contracts/utils";
import NormalButton from "../../components/Buttons/NormalBtn";
import { ZERO_ADDRESS } from "../../common/constants";
import { BigNumber } from "ethers";
import { BlockOutlined } from "@ant-design/icons";
import useAssetDisplay from "../../hooks/useAssetDisplay";
import AssetViewLayered from "../../components/AssetViewLayered";

const CopyExplorePage: FC = () => {
  const { connect, isTargetNetwork, connectedWallet, targetNetwork, getSigner } = useContext<IWeb3Context>(Web3Context);
  let params: any = useParams();

  const [copyInfo, setCopyInfo] = useState<CopyViewStructOutput>();
  const [imageUrl, setImageUrl] = useState<string>();

  const [isOwner, setIsOwner] = useState<boolean>();
  const [isCreator, setIsCreator] = useState<boolean>();
  const [creatorExists, setCreatorExists] = useState<boolean>();

  const [transferAddress, setTransferAddress] = useState<string>();
  const [isApproved, setIsApproved] = useState<boolean>();

  const [error, setError] = useState<boolean>();

  let history = useHistory();

  const data = useAssetDisplay(copyInfo?.tokenURI!);

  useEffect(() => {
    if (!connectedWallet) {
        connect();
    }
    isTargetNetwork();
  }, [])

  const maxCell = 24;
  
  useEffect(() => {
    fetchInfoOnChain();
    const intervalId = setInterval(fetchInfoOnChain, 5000);
    return () => clearInterval(intervalId);
  }, [])

  useEffect(()=>{
    if (copyInfo) {
      isExtendBalanceApproved().then(
        (v) => setIsApproved(v)
      );
    }
  }, [isOwner])

  const fetchInfoOnChain = () => {
    fetchCopyInfomation().then(
      (res)=>{
        setCopyInfo(res);
        isTokenCreator(res?.creatorId!).then(
          (v) => {
            setIsCreator(v)
            setCreatorExists(true);
          }
        ).catch((err)=>{
          setCreatorExists(false);
          console.log(err);
        })
        fetchDisplayContent(res!.tokenURI).then(
          (resp) => {setImageUrl(resp!.url)}
        )
      }
    ).catch((err) => {console.log(err)});

    isTokenOwner().then(
      (v) => {
        setIsOwner(v);
      }

    ).catch((err)=>{console.log(err)})
  }
  
  const fetchCopyInfomation = async () => {
    try {
      let contracts = await Contracts(await getSigner(), targetNetwork!);
      return await contracts.helper.getCopyTokenById(params.copy);
    } catch (error: any) {
      if( error.code == "CALL_EXCEPTION") {
        setError(true);
      }
    }
  }

  const isTokenOwner = async () => {
    let copyAddress = getContracts(targetNetwork)?.copy;
    let signer = await getSigner();
    let owner = await getNFTOwner(signer, copyAddress!, params.copy);
    return owner == (await signer.getAddress());
  }

  const isTokenCreator = async (creatorId: BigNumber) => {
    let creatorAddress = getContracts(targetNetwork)?.creator;
    let signer = await getSigner();
    let owner = await getNFTOwner(signer, creatorAddress!, creatorId);
    return owner == (await signer.getAddress());
  }

  const isExtendBalanceApproved = async (): Promise<boolean> => {
    let fee = copyInfo!.mintable.valInfo.mintAmount;
    let feeToken = copyInfo!.mintable.valInfo.feeToken;

    if (feeToken == ZERO_ADDRESS || fee.gt(0)) return true;

    let signer = await getSigner();
    let mintableAddress = getContracts(targetNetwork)?.mintable;
    let allowance = await getFTAllowance(signer, feeToken, await signer.getAddress(), mintableAddress!);

    return (allowance as BigNumber).gte(fee);
  }

  const onClickCreationsHandler = () => {
    history.push(ROUTE_CREATION_ROOT + copyInfo?.creatorId.toString()); // TODO change
  }

  const onClickUpdateHandle = async () => {
    if (!copyInfo) return;
    if(!await isTargetNetwork()) return;
    let signer = await getSigner();

    let contracts = await Contracts(signer, targetNetwork);
    const tx = await contracts.copy.update(params.copy);
    await tx.wait();

    message.info("Update Successful!");
  }

  const onClickExtendHandle = async () => {
    if (!copyInfo) return;
    if(!await isTargetNetwork()) return;
    let signer = await getSigner();

    let contracts = await Contracts(signer, targetNetwork);
    console.log(copyInfo.mintable.valInfo.duration.toString(), copyInfo?.mintable.valInfo.extendAmount.toString());
    const tx = await contracts.copy.extend(params.copy, copyInfo.mintable.valInfo.duration, {value: copyInfo?.mintable.valInfo.extendAmount});
    await tx.wait();

    message.info("Extend Successful!");
  }

  const onClickTransferHandle = async () => {
    if (!copyInfo)return;
    if (copyInfo.mintable.mintInfo.transferable) {
      if(!await isTargetNetwork()) return;
      let signer = await getSigner();
      let contracts = await Contracts(signer, targetNetwork);
      const tx = await contracts.copy.transferFrom(await signer.getAddress(), transferAddress!, params.copy);
      await tx.wait();
      message.info("Transfer Successful!");
    }
  }

  const onChangeTextHandle: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value: inputValue } = e.target;
    if (inputValue.length > 42) return;

    const reg = /^[a-fA-F0-9]+$/;
    if (inputValue === '' || inputValue === '0' || inputValue === '0x' || inputValue.slice(0,2) === "0x" && reg.test(inputValue.slice(2, inputValue.length))){
      setTransferAddress(inputValue);
    }
  }

  const onClickRevokeHandle = async ()=>{
    if(!await isTargetNetwork()) return;
    let signer = await getSigner();
    let contracts = await Contracts(signer, targetNetwork);
    const tx = await contracts.copy.revoke(params.copy);
    await tx.wait();
    message.success("Token Revoked");
  }

  const onClickBurnHandle = async () => {
    if(!await isTargetNetwork()) return;
    let signer = await getSigner();
    let contracts = await Contracts(signer, targetNetwork);
    const tx = await contracts.copy.destroy(params.copy);
    await tx.wait();
    message.success("Token Destroyed");
  }

  return <DefaultLayout>
            {copyInfo &&
            <div className="copy-root">
              <Row gutter={[16, 24]}>
              
              <Col className="gutter-row" xs={maxCell} sm={maxCell} lg={maxCell} xl={10} >
                <div className='copy-image-container'>
                  {/* <img className="copy-image" width={'100%'} height={'auto'} src={imageUrl} alt="Token Image"/> */}
                  <AssetViewLayered className="copy-image" tokenId={copyInfo.creatorId.toString()} data={data} width={'100%'} height={'auto'} />
                </div>
              </Col>

              <Col className="gutter-row" xs={maxCell} sm={maxCell} lg={maxCell} xl={14}>
                <div className="copy-info">
                    <Typography.Title level={4}>
                      Copy Token ID: {params.copy}
                    </Typography.Title>
                    <Typography.Title level={4}>
                      {"Creator Token ID: "}
                    <a onClick={onClickCreationsHandler} target={"_blank"} rel="noreferrer">{copyInfo?.creatorId.toString()}</a>
                    </Typography.Title>
                    <Typography.Title level={4}>
                      {"Owner: "}
                      <a href={resolveBlockExplorerUrl(copyInfo?.holder!, targetNetwork)} target={"_blank"} rel="noreferrer">
                        <BlockOutlined/>
                        <div>
                          <a href={ROUTE_ALL_COPIES + "?address=" + copyInfo?.holder!}>{copyInfo?.holder!}</a>
                        </div>
                      </a>
                    </Typography.Title>
                    <Typography.Title level={4}>
                      {"Arweave TxHash: "} 
                      <a href={imageUrl} target={"_blank"} rel="noreferrer">{copyInfo?.tokenURI}</a>
                    </Typography.Title>
                    { copyInfo?.mintable &&
                      <div>
                        <Typography.Title level={4}>{resolveExpiry(copyInfo.expireAt, "YYYY-MM-DD HH:mm:ss")}</Typography.Title>
                      </div>
                    }
                </div>
              </Col>
              <Divider className="copy-div"/>
              { isCreator && // Creator Panel
                <Col className="gutter-row" xs={maxCell} sm={maxCell} lg={maxCell} xl={24}>
                  <Typography.Title level={4}>
                      You are the creator !
                  </Typography.Title>

                  <div className="copy-panel-div">
                  <Typography.Title level={5} className="copy-panel-text"> 
                    {copyInfo?.mintable.mintInfo.revokable ? "Revoke the Ownership of This Token (Collector will be Unhappy)" : "Disabled" }
                  </Typography.Title>
                    <div className="copy-panel-button">
                      <NormalButton
                        placeholder={"Revoke Token"} 
                        disabled={!copyInfo?.mintable.mintInfo.revokable}
                        onClick={onClickRevokeHandle}/>
                    </div>
                  </div>

                </Col> 
              }
              { isCreator && <Divider className="copy-div"/>}
              { isOwner && // Collector Panel
                <Col className="gutter-row" xs={maxCell} sm={maxCell} lg={maxCell} xl={24}>
                  <Typography.Title level={4}>
                    You own this token !
                  </Typography.Title>
                  
                  <div className="copy-panel-div">
                  <Typography.Title level={5} className="copy-panel-text"> 
                    {copyInfo?.mintable.mintInfo.updatable ? "Update to the Latest TokenURI of the Creation" : "Disabled" }
                  </Typography.Title>
                    <div className="copy-panel-button">
                      <NormalButton
                        placeholder={"Update Token"} 
                        disabled={! (copyInfo?.mintable.mintInfo.updatable && creatorExists) }
                        onClick={onClickUpdateHandle}/>
                    </div>
                  </div>

                  <div className="copy-panel-div">
                      <Typography.Title level={5} className="copy-panel-text"> 
                      { copyInfo?.mintable.mintInfo.extendable ? copyInfo.expireAt.gte(999999999999999) ?
                        "Your Token Never Expires" :
                        "Renewed " + resolveExpiry(
                        copyInfo?.expireAt.add(copyInfo?.mintable.valInfo.duration), "YYYY-MM-DD HH:mm:ss"
                      ) +  " (" + resolveMintFee(copyInfo?.mintable.valInfo.extendAmount, copyInfo?.mintable.valInfo.feeToken, targetNetwork) +  ")": "Disable"}
                      </Typography.Title>
                      <div className="copy-panel-button">
                        <NormalButton 
                          placeholder={isApproved ? "Extend Expiry" : "Approve"} 
                          disabled={!copyInfo?.mintable.mintInfo.extendable || copyInfo.expireAt.gte(999999999999999)}
                          onClick={onClickExtendHandle}/>
                      </div>
                  </div>

                  <div className="copy-panel-div">
                    { 
                      copyInfo?.mintable.mintInfo.transferable ?
                      <Input className="transfer-input" value={transferAddress} placeholder="To Address" onChange={onChangeTextHandle}/> :
                      <Typography.Title level={5} className="copy-panel-text">Disabled</Typography.Title>
                    }
                    <div className="copy-panel-button">
                      <NormalButton
                        placeholder={"Transfer Token"} 
                        disabled={!(copyInfo?.mintable.mintInfo.transferable && transferAddress?.length == 42)}
                        onClick={onClickTransferHandle}/>
                    </div>
                  </div>

                  <div className="copy-panel-div">
                    <Typography.Title level={5} className="copy-panel-text">Permanently Destroy Token</Typography.Title>
                    <div className="copy-panel-button">
                      <NormalButton
                        placeholder={"Burn Token"}
                        onClick={onClickBurnHandle}/>
                    </div>
                  </div>
                </Col>
              }
              { isOwner && <Divider className="copy-div"/>}
              { copyInfo?.mintable &&
                <MintableItem mintable={copyInfo?.mintable}/>
              }
            </Row>
          </div>}
          
          {error && 
            <div className="copy-error">
              <Typography.Title>{"Token " + params.copy + " does not exist"}</Typography.Title>
            </div>
          }
        </DefaultLayout>
}

export default CopyExplorePage;