import Web3 from "web3";

export const CHAIN_ID = {
  POLYGON_TESTNET: 80001,
  POLYGON_MAINNET: 137,
  ROPSTEN: 3,
  SEPOLIA: 11155111
}

export const NETWORKS : any = {
  3: {
    chainId: Web3.utils.toHex(3), // '0x89'
    chainName: "Ropsten", 
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: [""],
    blockExplorerUrl: "https://ropsten.etherscan.io/",
  },
  137: {
    chainId: Web3.utils.toHex(137), // '0x89'
    chainName: "Polygon Mainnet", 
    nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrl: "https://www.polygonscan.com/",
  },
  80001: {
    chainId: Web3.utils.toHex(80001), // '0x13881'
    chainName: "Polygon Testnet",
    nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
    rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
    blockExplorerUrl: "https://mumbai.polygonscan.com/",
  },
  11155111: {
    chainId: Web3.utils.toHex(11155111), // '0x13881'
    chainName: "Sepolia Testnet",
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: [""],
    blockExplorerUrl: "https://sepolia.etherscan.io/",
  }
};