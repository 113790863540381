import { ZERO_ADDRESS } from "./constants";
import { CHAIN_ID } from "./networks";

interface Token {
  address: string,
  decimals: number,
  symbol: string
}

type Network = Record<string, Token>;

export const polygon: Network = {
  matic: {
    address: ZERO_ADDRESS,
    decimals: 18,
    symbol: "MATIC"
  },
  usdt: {
    address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    decimals: 6,
    symbol: "USDT"
  },
  eth: {
    address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    decimals: 18,
    symbol: "WETH"
  }
}

export const ropsten: Network = {
  eth: {
    address: ZERO_ADDRESS,
    decimals: 18,
    symbol: "ETH"
  }
}

export const sepolia: Network = {
  eth: {
    address: ZERO_ADDRESS,
    decimals: 18,
    symbol: "ETH"
  }
}

export const mumbai: Network = {
  matic: {
    address: ZERO_ADDRESS,
    decimals: 18,
    symbol: "MATIC"
  }
}

export const getTokens = (network: number) => {
  if (network === CHAIN_ID.POLYGON_MAINNET) return polygon;
  if (network === CHAIN_ID.ROPSTEN) return ropsten;
  if (network === CHAIN_ID.SEPOLIA) return sepolia;
  if (network === CHAIN_ID.POLYGON_TESTNET) return mumbai;
}