import { ethers, Signer } from 'ethers';
import { BigNumberish } from 'ethers';
import { keccak256, BytesLike } from 'ethers/lib/utils';
import { getContracts } from '../../common/contracts';
import { MockNFT__factory, MockFT__factory } from './typechain-types';

export type CopyMintTuple = [string, BigNumberish, Statement, boolean, boolean, boolean, boolean];
export type CopyValidationTuple = [string, BigNumberish, boolean, BigNumberish, BigNumberish, string, BigNumberish, BigNumberish, BigNumberish];

export enum Statement {
  COLLECT,
  USE,
  MODIFY,
  DISTRIBUTE
};

export interface CopyMintData {
  mintable: string,
  creatorId: BigNumberish;
  statement: Statement;
  transferable: boolean;
  updatable: boolean;
  revokable: boolean;
  extendable: boolean;
}

export interface CopyValidationData {
  feeToken: string;
  duration: BigNumberish;
  fragmented: boolean;
  mintAmount: BigNumberish;
  extendAmount: BigNumberish;
  requiredERC721Token: string;
  limit: BigNumberish;
  start: BigNumberish;
  time: BigNumberish;
}

export interface PermSig {
  deadline: number;
  v: number;
  s: BytesLike;
  r: BytesLike;
}


export const getMintData = (data: CopyMintData): CopyMintTuple => {
  return [
    data.mintable,
    data.creatorId,
    data.statement,
    data.transferable,
    data.updatable,
    data.revokable,
    data.extendable,
  ];
};

export const getEncodedValidationData = (validationInfo: CopyValidationTuple) => {
return ethers.utils.defaultAbiCoder.encode(
  ['tuple(address, uint64, bool, uint256, uint256, address, uint256, uint64, uint64)'],
  [validationInfo]
  );
};

export const getCopyValidationData = (data: CopyValidationData): CopyValidationTuple => {
  return [
    data.feeToken,
    data.duration,
    data.fragmented,
    data.mintAmount,
    data.extendAmount,
    data.requiredERC721Token,
    data.limit,
    data.start,
    data.time
  ];
};

export const getNow = (): number => {
  return Math.floor(new Date().getTime() / 1000);
}

export const getDeadline = (seconds: number) => {
  return getNow() + seconds;
};

const getPermMessage = (contentUri: string, copyrightStatement: string, deadline: number) => {
  return keccak256(
    ethers.utils.defaultAbiCoder.encode(
      ['string', 'string', 'uint256'],
      [contentUri, copyrightStatement, deadline]
    )
  );
};

const parseSignature = (signature: string) => {
  let r = "0x" + signature.slice(2, 66);
  let s = "0x" + signature.slice(66, 130);
  let v = signature.slice(130, 132) == "1b" ? 27 : 28;
  return {
    v: v,
    s: s,
    r: r
  }
}

export const getPermSig = async (signer: Signer, contentUri: string, copyrightStatement: string, offset: number): Promise<PermSig> => {
  
  const deadline = getDeadline(offset);
  const message = getPermMessage(contentUri, copyrightStatement, deadline);
  let signature = await signer.signMessage(Buffer.from(message.slice(2), 'hex'));
  const { v, r, s } = parseSignature(signature);
  return {
    deadline: deadline,
    v: v,
    r: r,
    s: s,
  };
};

export const getERC721Contract = (signer: Signer, nftAddress: string) => {
  return (new MockNFT__factory(signer)).attach(nftAddress);
}

export const getERC20Contract = (signer: Signer, ftAddress: string) => {
  return (new MockFT__factory(signer)).attach(ftAddress);
}

export const getNFTBalance = async (signer: Signer, nftAddress: string, ownerAddress: string): Promise<BigNumberish> => {
  let contract = getERC721Contract(signer, nftAddress);
  return await contract.balanceOf(ownerAddress);
}

export const getNFTOwner = async (signer: Signer, nftAddress: string, tokenId: BigNumberish): Promise<string> => {
  let contract = getERC721Contract(signer, nftAddress);
  return await contract.ownerOf(tokenId);
}

export const getFTBalance = async (signer: Signer, ftAddress: string, ownerAddress: string): Promise<BigNumberish> => {
  let contract = getERC20Contract(signer, ftAddress);
  return await contract.balanceOf(ownerAddress);
}

export const getFTAllowance = async (signer: Signer, ftAddress: string, ownerAddress: string, spenderAddress: string): Promise<BigNumberish> => {
  let contract = (new MockFT__factory(signer)).attach(ftAddress);
  return await contract.allowance(ownerAddress, spenderAddress);
}
