import { CopyOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Typography, Space } from "antd";
import { FC, useState } from "react";
import { useHistory } from "react-router";
import { ROUTE_ALL_CREATIONS, ROUTE_BASE, ROUTE_CREATE, ROUTE_MY_ASSETS, ROUTE_FAQS, ROUTE_FAUCET, ROUTE_NAME } from "../../common/route";
import StackedMobileMenu from "./stackedMobileMenu";

const DefaultNavbar: FC = () => {
    const history = useHistory();
    const MENU_OPTIONS = [
        { label: 'Home', route: ROUTE_BASE },
        // { label: '.art', route: ROUTE_NAME },
        { label: 'Discover', route: ROUTE_ALL_CREATIONS },
        { label: 'Create', route: ROUTE_CREATE },
        { label: 'Faucet', route: ROUTE_FAUCET },
        { label: 'FAQs', route: ROUTE_FAQS }
    ];
    
    const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

    const handleMenuClick = (route: string) => history.push(route);

    const handleMenuToggle = () => {
        setMobileMenuOpen((prev) => !prev);
    };

    return (
        <div id="navbar">
            <a onClick={e => handleMenuClick(ROUTE_BASE)} >
                <Space className="copy-logo"><img src="/logo.jpeg" className="copy-logo-icon"/><Typography.Title level={4}>Distributor Beta</Typography.Title></Space>
            </a>
            <div id="menu">
                {MENU_OPTIONS.map((o) => <span
                    key={`navbar:${o.route}`}
                    className={`rd-menuItem ${history.location.pathname === o.route ? 'active' : ''}`}
                    onClick={e => handleMenuClick(o.route)}
                >
                    {o.label}
                </span>)}
                <Button className="rd-menuItem" type="primary" shape="round" onClick={e => handleMenuClick(ROUTE_MY_ASSETS)}>
                    My Assets
                </Button>
            </div>
            <div id='menu-ham'>
                {mobileMenuOpen
                    ? <StackedMobileMenu toggleMenu={handleMenuToggle} />
                    : <MenuOutlined onClick={handleMenuToggle} />
                }
            </div>
        </div>
    )
};

export default DefaultNavbar;
