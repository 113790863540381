import { Button } from 'antd';
import { FC } from 'react';

interface PropsType {
  placeholder: any,
  onClick: any | null,
  disabled?: boolean
}

const NormalButton: FC<PropsType> = ({ 
    placeholder, 
    onClick = null,
    disabled = false
}) => {
    return (
            <Button 
                onClick={onClick} 
                size='large'
                shape='round'
                type='primary'
                disabled={disabled}
            >
                {placeholder}
            </Button>
    );
};

export default NormalButton;
