
import { CHAIN_ID } from './networks';

export const CDN_ROOT = ''

export const ipfsContentRoot = 'https://ipfs.io/ipfs/';
export const ipfsCDNRoot = '';
export const ipfsCloudFrontRoot = '';

export const bundlerUrl = 'https://node1.bundlr.network';

export const ipfsNodeUrl = 'https://copydistributor.infura-ipfs.io';
export const subgraphUrlRoot = '';

export const TARGET_NETWORK = CHAIN_ID.POLYGON_MAINNET;